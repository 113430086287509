import SourceFlowText from '@sourceflow-uk/sourceflowtext';
import React, {
    useRef,
    useEffect
} from "react";
import Link from 'next/link';
import {
    Container
} from "reactstrap";
import styles from './styles.module.scss';
import { useRouter } from "next/router";


export default function Fourohfoursection({
    global,
    customClass
}) {
    const router = useRouter();
    const ref = useRef(null);
    React.useEffect(() => {
        import("@lottiefiles/lottie-player");
    });
    return (
        <section className={`${styles.root} ${customClass} position-relative text-center`}>
            <Container>
                <div className="d-none">
                    <h1>404.Page Not Found</h1>
                </div>
                <lottie-player
                    autoplay
                    loop
                    mode="normal"
                    src="https://assets9.lottiefiles.com/packages/lf20_kcsr6fcp.json"
                >
                </lottie-player>
                <aside>
                    <SourceFlowText global={global} path="four_oh_four.content" type="html">
                        <p className='h3'>The content you are searching for is no longer available</p>
                    </SourceFlowText>
                    <div className='d-flex flex-wrap justify-content-center gap-5 mt-5'>
                        <div>
                            <p className='h5'>To see our available roles click here:</p>
                            <Link href={`${router.asPath.startsWith("/us")
                                    ? "/us/jobs"
                                    : "/jobs"
                                    }`}>
                                <a className="primaryBtn no-arrow">
                                    <span className="d-inline-block">
                                        Job Search
                                    </span>
                                </a>
                            </Link>
                        </div>
                        <div>
                            <p className='h5'>To see how we can support your business:</p>
                            <Link href={`${router.asPath.startsWith("/us")
                                    ? "/us/business-solutions"
                                    : "/business-solutions"
                                    }`}>
                                <a className="primaryBtn no-arrow">
                                    <span className="d-inline-block">
                                        Business Solutions
                                    </span>
                                </a>
                            </Link>
                        </div>
                    </div>
                    <div className='mt-5'>
                        <p className='h5'>- or -</p>
                        <Link href={`/`}>
                            <a className="primaryBtn">
                                <span className="d-inline-block">
                                    Go back home
                                </span>
                            </a>
                        </Link>
                    </div>
                </aside>
            </Container>
        </section>
    )
}